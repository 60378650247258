<template>
  <div class="flex flex-col mt-6 mb-10 justify-start items-start min-height">
    <span class="text-xl text-gray-900 font-semibold"
      >Gestión <small>[{{ body.length }}]</small></span
    >
    <span class="text-xl text-gray-900 font-semibold"
      ><small>{{ profesional }}</small></span
    >
    <div class="w-full mt-2">
      <input-search v-model="search" />
      <table-component
        class="w-full mt-4"
        :loading="loading"
        :head="[
          { key: 'lastnames', label: 'Apellidos Paciente' },
          { key: 'names', label: 'Nombres Paciente' },
          { key: 'start_date', label: 'Fecha de atención' },
          { key: 'start_time', label: 'Hora de atención' },
          { key: 'status', label: 'Estado Reserva' },
          { key: 'value', label: 'Ingreso por prestación' },
          { key: 'license', label: 'Licencia' },
          { key: 'approval', label: 'Aprobación' },
          { key: 'comments', label: 'Comentarios evaluación' },
        ]"
        :body="body"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import AdminService from '@/services/adminService';
import InputSearch from '@/components/form/InputSearch.vue';
import Table from '@/components/dashboard/user/admin/Table.vue';

export default {
  name: 'Profesional',
  components: {
    'input-search': InputSearch,
    'table-component': Table,
  },
  data() {
    return {
      loading: true,
      search: '',
      management: null,
      profesional: '',
    };
  },
  created() {
    this.updateData();
  },
  methods: {
    updateData() {
      AdminService.getProfesionalManagement(this.$route.params.user_id)
        .then(response => {
          this.management = response.data.management;
          this.profesional = response.data.profesional;
          this.loading = false;
        })
        .catch(() => {
          this.$Error.fire({
            title: 'Error al obtener los datos del profesional',
          });
          setTimeout(() => {
            this.$router.replace({ path: '/' });
          }, 2000);
        });
    },
    formatDateNative(date) {
      moment.locale('es');
      let date_temp = moment(date, 'YYYY-MM-DD HH:mm');
      date_temp.locale(false);
      return date_temp.format('L');
    },
    formatDateNativeHour(date) {
      moment.locale('es');
      let date_temp = moment(date, 'YYYY-MM-DD HH:mm');
      date_temp.locale(false);
      return date_temp.format('HH:mm A');
    },
    chileanPesos(value) {
      let chileanPeso = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      });
      return chileanPeso.format(value).replace(/,/g, '.');
    },
    addMonth(date) {
      const [day, month, year] = date.split('/');
      return `${day}/${parseInt(month) + 1}/${year}`;
    },
  },
  watch: {
    '$route.params.user_id'() {
      this.updateData();
    },
  },
  computed: {
    ...mapGetters({
      user: ['authentication/user'],
    }),
    body() {
      let temp = this.management ? this.management : [];
      if (this.search != '') {
        temp = temp.filter(e => {
          let name_temp = `${e.lastnames} ${e.names}`;
          return name_temp.toLowerCase().includes(this.search.toLowerCase());
        });
      }
      return temp.map(e => {
        return {
          lastnames: {
            label: e.lastnames,
            class: 'capitalize',
          },
          names: {
            label: e.names,
            class: 'capitalize',
          },
          start_date: {
            label: e.start_date,
          },
          start_time: {
            label: e.start_time,
          },
          status: {
            label: e.status,
            class:
              e.status === 'Aceptado'
                ? 'text-green-900'
                : e.status === 'Rechazado'
                ? 'text-red-900'
                : e.status === 'Pendiente'
                ? 'text-yellow-900'
                : 'text-blue-900',
          },
          value: {
            label: this.chileanPesos(e.value),
            class: 'capitalize',
          },
          license: {
            label: e.license,
          },
          approval: {
            label: e.approval,
            class:
              e.approval === 'Sin evaluaci\u00f3n'
                ? 'text-gray-400 capitalize'
                : 'capitalize',
          },
          comments: {
            label: e.comments,
            class:
              e.comments === 'Sin evaluaci\u00f3n'
                ? 'text-gray-400 max-w-64 w-64'
                : 'max-w-64 w-64',
          },
        };
      });
    },
  },
};
</script>

<style scoped>
.min-height {
  min-height: 80vh;
}
</style>
