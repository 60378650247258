var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col mt-6 mb-10 justify-start items-start min-height"},[_c('span',{staticClass:"text-xl text-gray-900 font-semibold"},[_vm._v("Gestión "),_c('small',[_vm._v("["+_vm._s(_vm.body.length)+"]")])]),_c('span',{staticClass:"text-xl text-gray-900 font-semibold"},[_c('small',[_vm._v(_vm._s(_vm.profesional))])]),_c('div',{staticClass:"w-full mt-2"},[_c('input-search',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('table-component',{staticClass:"w-full mt-4",attrs:{"loading":_vm.loading,"head":[
        { key: 'lastnames', label: 'Apellidos Paciente' },
        { key: 'names', label: 'Nombres Paciente' },
        { key: 'start_date', label: 'Fecha de atención' },
        { key: 'start_time', label: 'Hora de atención' },
        { key: 'status', label: 'Estado Reserva' },
        { key: 'value', label: 'Ingreso por prestación' },
        { key: 'license', label: 'Licencia' },
        { key: 'approval', label: 'Aprobación' },
        { key: 'comments', label: 'Comentarios evaluación' } ],"body":_vm.body}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }